.app__footer {
 
  width: 100vw;


  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
    overflow: hidden;
    padding: 0px !important;  
margin-bottom: -12vw !important 

}

 
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden; /* This prevents scrollbars if content overflows */
@media screen and(max-width:650px) { 
height: 100%!important; 
  margin-bottom: -90px!important;

}


@media screen and(min-width:650px) and (max-width:950px)  { 
  height: 100vh!important;
  } 
}
.map-iframe {
  width: 100vw;

height: auto;
margin-bottom: -0.5vw;

  border: none;


  transition: transform 0.3s ease-in-out;

  @media screen and (max-width: 650px) {
    width: 100%;
    border-radius: 0px;
height: 20%;
  }
  @media screen and(min-width:650px) and (max-width:950px)  { 
height: 41%;

  
  } 

  

  .map {
    position: absolute;
    width: 100vw;
    overflow: hidden;
   
  }
}

.map-container:hover .map-iframe {
  transform: scale(1.05);
}

.local{
  background-color: rgba(16, 16, 16, 0.954)!important;
height: 62px;
  color: white;
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  width: 100%;
}




