.app::sectionOne {
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: 150vh !important;
  position: relative;

  @media screen and (max-width: 1000px)and (min-width: 650px) {
    flex-direction: column;
    gap: 0px;
    padding: 0px 0px;
    align-items: center;
    justify-content: center;

  }

  @media screen and (max-width: 650px) {
    height: auto !important;
    align-items: center;
    justify-content: center;


  }
}


.about-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 150px;
  align-items: center;
  position: relative;



  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
    gap: 0px !important;
    padding-bottom: 50px;
    width: 95% !important;
    gap: none;
  }

  @media screen and (max-width: 1000px)and (min-width: 650px) {
    flex-direction: column-reverse;
    gap: 100px;
    padding: 0px 0px;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1366px)and (min-width: 950px) {
    margin-bottom: 50px;
  }


}

.img-gruas {
  width: 40%;

  padding-left: 80px;
  margin-bottom: 100px;
  // animation: slideAnimation 3.3s linear infinite;

  /* Transition for background-color property */
  @media screen and (max-width: 650px) {
    flex-direction: column;
    gap: 0px;
    padding: 0px 0px;
    width: 90% !important;
    gap: none;
    margin: 0px;
  }

  @media screen and (min-width: 650px) and (max-width: 950px) {
    flex-direction: column;
    gap: 0px;
    padding: 0px 0px;
    width: 70% !important;
    gap: none;
    margin: 0px;
    padding-bottom: 30px;
  }
}

// @keyframes slideAnimation {
//   0% {
//     transform: translateX(5);
//   }

//   50% {
//     transform: translateX(4px) translateY(2px);
//   }

//   100% {
//     transform: translateX(2);
//   }
// }



.about-desc-one {
  width: 70% !important;
  border-style: none !important;
  text-align: justify !important;
  margin-top: -180px;

  @media screen and (max-width: 650px) {
    width: 90vw !important;
    height: 100% !important;
    border-style: none;
    margin-top: 0px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1000px) and (min-width: 650px) {
    width: 100% !important;
    height: 100%;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -70px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1366px) {
    width: 100vw;
    text-align: right;
  }

  p {
    color: rgb(112, 112, 112) !important;
    line-height: 30px !important;
    width: 60%;


    @media screen and (max-width: 650px) {
      width: 95% !important;
      font-size: 14px;
    }

    @media screen and (min-width: 650px) and(max-width:950px) {
      width: 80% !important;
      font-size: 16px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1366px) {
      width: 80%;
    }
  }

  .button-about {
    display: flex;
    align-items: center;
gap: 5px;
    margin-top: 10px;
    margin-bottom: -20px;
    margin-left: 100px;
    height: 50px;
border: none;
    width: 30%;
 background: none;
    transition:  transform 1.3s ease-in-out;
    /* Transition for background-color property */
    cursor: pointer;

    @media screen and (max-width: 650px) {
      margin-left: 0px;
      font-size: 14px !important;
      width: 80vw;
      align-items: center !important;
      justify-content: center !important;

    }

    @media screen and (max-width: 1000px) and (min-width: 650px) {
      justify-content: center;
      width: 80vw;
      align-items: center !important;
      margin-left: -20px;
      padding-top: 20px;


    }

    @media screen and (max-width: 1366px) and (min-width: 1000px) {

      margin: 0px;
      text-align: center;
      padding-top: 20px;
      width: 50%;

    }
  }

  @keyframes moveRightAndBack {

    0%,
    100% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(50px);
    }
  }

  // .arrowspan {
  //   font-size: 19px;
  //   color: rgba(5, 26, 50, 0.9);
  //   // animation: moveRightAndBack 2s linear infinite;
  //   /* Infinite loop animation */
  //   // transition: color 0.3s;
  //   /* Transition for color change */
  // }

  // .button-text:hover {
  //   transform: translate3d(0, -5px, 0);
  //   /* Move the button 5px down when hovering */
  //   /* Change to the desired hover color */
  // }


  .button-text {
    margin-left: 8%;
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    color: rgba(5, 26, 50, 0.9);
    position: relative;
    z-index: 1;
    /* Place the text above the pseudo-element */


    /* Add transitions for transform and color */
    @media screen and(max-width:650px) {
      margin-left: 0%;
      font-size: 12px !important;


    }

  }





  h1 {
    margin-bottom: 50px !important;
    padding-top: 13px;
    font-size: 50px !important;
    font-weight: lighter;
    color: rgba(5, 26, 50, 0.9);
    margin-left: -5vw;

    @media screen and(max-width:650px) {
      margin-left: 0vw;
      font-size: 25px !important;
      margin-bottom: 20px !important;
      padding-top: 0px !important;


    }

    @media screen and(min-width:650px) and (max-width:1000px) {

      width: 90% !important;
      margin-left: 0;
    }
  }
}

.slogan {
  color: tan;
  margin-left: -40px;
  padding-top: 100px;

  @media screen and(max-width:650px) {
    font-size: 12px;
    margin-bottom: -12px;
    margin-left: -5px;
    padding-top: 0px;
  }
}

.logo-about {
  @media screen and(max-width:650px) {
    width: 35px !important;
    margin: 0px !important;
  }


}



.line2 {
  width: 67%;
  height: 0.5px;
  background-color: tan;
  margin-bottom: 40px;
  margin-top: -48px;
  margin-left: -5%;

  @media screen and(max-width:650px) {
    width: 20%;
    margin-left: 0%;
    margin-top: -30px;
    margin-bottom: 20px;


  }

  @media screen and(min-width:650px) and(max-width:1000px) {
    width: 10%;



  }
}



/* Add styles for the image slider container */


.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 800px;

  @media screen and(max-width:650px) {
    height: 600px;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    height: 800px;
  }

}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 20px;
  position: absolute;
  height: 800px;
  opacity: 0.8;
  /* Start with 0.6 opacity */
  transition: opacity 0.8s ease;

  /* Smooth opacity transition */
  @media screen and(max-width:650px) {
    height: 600px !important;
  }

}

.carousel-slide.active {
  opacity: 1;
  /* Set opacity to 1 for the active slide */
  transition: opacity 0.8s ease;
  /* Smooth opacity transition */
}

.carousel-slide .title {
  font-size: 2px;
  margin-bottom: 10px;

  @media screen and(max-width:650px) {
    font-size: 35px !important;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    font-size: 50px !important;
  }
}

.carousel-slide .content {
  font-size: 18px;
  width: 40%;

  @media screen and(max-width:650px) {
    font-size: 13px !important;
    width: 100%;
    padding: 10px !important;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    font-size: 18px !important;
    width: 80%;
    padding: 10px !important;
  }

}

.carousel-button-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;

  // gap: 1575px;
  @media screen and(max-width:650px) {
    justify-content: center;
    align-items: end;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    justify-content: center;
    align-items: end;
  }
}

.carousel-button1 {
  background: rgba(0, 0, 0, 0.5);
  color: rgba(254, 254, 254, 0.815);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  height: 800px;
  order: 1;
  width: 10%;
  font-size: 100px bold !important;
  font-size: 0.8vw;
  font-weight: bold;

  @media screen and(max-width:650px) {
    justify-content: center;
    height: 50px;
    width: 50%;
    font-size: 3vw;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    justify-content: center;
    height: 50px;
    width: 50%;
    font-size: 2vw;
  }
}

.carousel-button2 {
  background: rgba(0, 0, 0, 0.5);
  color: rgba(254, 254, 254, 0.815);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  height: 800px;
  order: 2;
  width: 10%;
  font-size: 0.8vw;
  font-weight: bold;

  @media screen and(max-width:650px) {
    justify-content: center;
    height: 50px;
    width: 50%;
    font-size: 3vw;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    justify-content: center;
    height: 50px;
    width: 50%;
    font-size: 2vw;
  }
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Slide 0 */
.slide-0 .content-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end !important;
  /* Align to the right side */
  text-align: right;
  /* Align text to the right */
  height: 100%;
  /* Ensure it takes full slide height */
  padding: 50px;
  /* Adjust padding as needed */
  padding-right: 200px;

  @media screen and(max-width:650px) {
    align-items: end !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 150px !important;
    margin: 0px !important;



  }

  @media screen and(min-width:650px) and(max-width:950px) {
    align-items: end !important;
    width: 100%;
    padding-left: 100px !important;
    padding-right: 0px;

    margin: 0px !important;

  }

}

.slide-0 .line-vision {
  width: 50%;
  height: 2px;
  background-color: white;
  margin-bottom: 40px;

  @media screen and(max-width:650px) {
    margin-bottom: 0px;
    width: 130%;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    width: 70%;
    margin-right: 80px !important;
  }

  transform: translateX(100%);
  animation: scale-0 3s forwards
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    /* Move to the initial position */
  }
}

.slide-0 .title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 75px;
  padding-bottom: 30px;
  color: white;
  ;
  transform: scale(0.94);
  animation: scale-0 3s forwards cubic-bezier(0.5, 1, 0.89, 1), fadeInScale-0 3s forwards cubic-bezier(0.80, 2, 0.1, 10);

  @media screen and(max-width:650px) {
    padding-bottom: 20px;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding-bottom: 20px;
    padding-right: 70px;

  }
}

.slide-0 .content {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  margin-right: 75px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding-top: 30px;
  transform: scale(0.94);
  padding: 2px;
  animation: scale-0 3s forwards cubic-bezier(0.5, 1, 0.89, 1), fadeInScale-0 3s forwards cubic-bezier(0.80, 2, 0.1, 10);

  @media screen and(max-width:650px) {
    margin-right: 0px;
    width: 300px !important;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    width: 445px !important;
    font-size: 23px !important;


  }

}

/* Animation keyframes for Slide 0 */
@keyframes scale-0 {
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInScale-0 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Slide 1 */
.slide-1 .content-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
  /* Align to the right side */
  text-align: left;
  /* Align text to the right */
  height: 100%;
  /* Ensure it takes full slide height */
  padding: 50px;
  /* Adjust padding as needed */
  padding-left: 200px;
  white-space: pre-line !important;

  @media screen and(max-width:650px) {
    padding: 0px;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding: 0px;
  }
}

.slide-1 .line-vision {
  width: 55%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.207);
  margin-bottom: 40px;

  @media screen and(max-width:650px) {
    margin-bottom: 0px;
    width: 100%;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    width: 100%;

  }

  transform: translateX(-100%);
  animation: slideFromLeft 3s forwards
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
    /* Move to the initial position */
  }
}

.slide-1 .title {
  text-shadow: 2px 2px 4px white;
  font-size: 75px;
  padding-bottom: 30px;
  color: rgba(0, 0, 0, 0.726);
  transform: scale(0.94);
  animation: scale-1 3s forwards cubic-bezier(0.5, 1, 0.89, 1), fadeInScale-1 3s forwards cubic-bezier(0.80, 2, 0.1, 10);

  @media screen and(max-width:650px) {
    padding-bottom: 20px;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding-bottom: 20px;
    padding-right: 65px;


  }
}

.slide-1 .content {
  text-shadow: 1px 1px 2px white;
  padding-top: 30px;
  margin-left: 75px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.668);
  font-weight: bold;

  width: 50%;
  padding: 15px;
  transform: scale(0.94);
  animation: scale-1 3s forwards cubic-bezier(0.5, 1, 0.89, 1), fadeInScale-1 3s forwards cubic-bezier(0.80, 2, 0.1, 10);

  @media screen and(max-width:650px) {
    padding: 0px;
    width: 300px !important;
    margin-left: 0px;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding: 0px;
    width: 500px !important;
    font-size: 21px !important;


  }
}

/* Animation keyframes for Slide 1 */
@keyframes scale-1 {
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInScale-1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Slide 2 */
.slide-2 .content-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
  /* Align to the right side */
  text-align: left;
  /* Align text to the right */
  height: 100%;
  /* Ensure it takes full slide height */
  padding: 50px;
  /* Adjust padding as needed */
  padding-left: 200px;
  white-space: pre-line !important;

  @media screen and(max-width:650px) {
    padding: 0px;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding: 0px;

  }
}

.slide-2 .line-vision {
  width: 55%;
  height: 2px;
  background-color: white;
  margin-bottom: 40px;

  @media screen and(max-width:650px) {
    margin-bottom: 0px;
    width: 100%;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    width: 100%;

  }

  transform: translateX(-100%);
  animation: slideFromLeft2 3s forwards
}

@keyframes slideFromLeft2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
    /* Move to the initial position */
  }
}

.slide-2 .title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

  font-size: 75px;
  padding-bottom: 30px;
  color: rgba(255, 255, 255, 0.938);
  transform: scale(0.94);
  animation: scale-2 3s forwards cubic-bezier(0.5, 1, 0.89, 1), fadeInScale-2 3s forwards cubic-bezier(0.80, 2, 0.1, 10);

  @media screen and(min-width:650px) and(max-width:950px) {
    padding-bottom: 20px;



  }
}

.slide-2 .content {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

  margin-left: 75px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.938);
  font-weight: bold;
  padding-top: 30px;
  width: 50%;

  @media screen and(max-width:650px) {
    padding: 0px;
    width: 330px !important;
    margin-left: 0px;
  }

  @media screen and(min-width:650px) and(max-width:950px) {
    padding: 0px;
    width: 480px !important;
    font-size: 23px !important;

  }

  transform: scale(0.94);
  animation: scale-2 3s forwards cubic-bezier(0.5, 1, 0.89, 1),
  fadeInScale-2 3s forwards cubic-bezier(0.80, 2, 0.1, 10);
}

/* Animation keyframes for Slide 2 */
@keyframes scale-2 {
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInScale-2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.arrowsflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;

  @media screen and(max-width:650px) {
    display: flex;
    flex-direction: row;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    display: flex;
    flex-direction: row;

  }
}

.arrowsflexRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;

  @media screen and(max-width:650px) {
    display: flex;
    flex-direction: row-reverse;

  }

  @media screen and(min-width:650px) and(max-width:950px) {
    display: flex;
    flex-direction: row-reverse;

  }
}

.arrow {
  font-size: 2vw;

  opacity: 0.8;
  cursor: pointer;
  transition: 0.3s;


}

ImArrowRight:hover,
ImArrowLeft:hover {
  color: rgba(11, 10, 61, 0.9);
}