@import url('https://fonts.cdnfonts.com/css/h-healan-baby');

.video-background {
    position: relative;
    width: 100vw;
    object-fit: cover !important;

    @media screen and (max-width: 650px) {
        position: relative;
        width: auto;
        height: 80%;
        object-fit: cover !important;

    }

    @media screen and (max-width:950px) and(min-width:650px) {
        // display: none;
        position: relative;
        width: auto;
        object-fit: scale-down !important;


    }

    @media screen and (min-width:2000px) {}

    // @media screen and (max-width:1350px) and(min-width:950px) {
    //     // display: none;
    //     position: relative;
    //     width: auto;
    //     height: 100vh!important;


    // }


}


.app__header-desc {
    color: white !important;
    animation: introHeaderOne 1.5s ease-in;

    @media screen and (max-width: 650px) {
        margin-bottom: -100px;
        animation: none;
    }

    @media screen and (max-width: 950px) and(min-width:650px) {
        margin: none;
        animation: none !important;


    }
}

.video-parent {
    position: relative;
    width: 100vw;
    top: 0;
    overflow: hidden;
    /* Ensure the video stays within the container */
    object-fit: cover !important;


    @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: -200px;
    }




}

#home {
    font-family: 'h Healan Baby', sans-serif;
    position: relative;
    background-size: 100vw;

    background-position: center;

    @media screen and (max-width: 950px) {
        width: 100vw;
        overflow: hidden;
    }

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}



.app__header {
    flex: 1;
    width: 100vw;
    flex-direction: row;
    position: relative;






    @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: auto;
    }

    @media screen and (max-width: 400px) {
        padding-top: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

// 



.app__header-main {
    position: absolute;
    top: 350px;
    left: 10%;
    right: 0;
    width: 80%;
    text-align: center;

    @media screen and (max-width: 650px) {
        top: 200px !important;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    @media screen and (max-width: 1366px) and (min-width:950px) {

        margin-top: -100px;

    }



    p {
        font-size: 1.5rem;
        font-weight: 100;
        color: rgb(34, 34, 34);
        margin-top: 60px;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;

        @media screen and (max-width: 600px) {
            font-size: 1rem;
            padding-top: 40px;
        }

        @media screen and (min-width: 600px) and (max-width:780px) {
            font-size: 1rem;
            padding-top: 40px;
        }

        @media screen and (max-width: 100px) {
            font-size: 1rem;
            padding-top: 40px;
        }
    }

    button {
        position: relative;
        font-family: var(--font-base);

        top: 0;
        margin-top: 40px;
        width: 20%;
        height: 80px;
        border-radius: 7px;
        border-style: none;
        background-color: rgba(255, 255, 255, 0);
        border-color: rgb(255, 253, 253);
        border-style: solid;
        border-width: 1px;
        color: rgb(255, 255, 255);
        font-size: 20px;
        padding-top: 0px;
        box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.158);
        transition: all .3s ease;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);

        animation: introHeaderOne 1.5s ease;

        @media screen and (max-width: 600px) {
            width: 80%;
            top: -30px;
            font-size: 20px;
            height: 50px;
            animation: none
        }

        @media screen and (max-width: 950px) {
            width: 80%;
            top: 187px;
            font-size: 20px;
            height: 50px;
            animation: none;
        }
    }

    button:hover {
        top: 8px;
        background-color: rgb(16, 15, 88);
        border-style: none;
        box-shadow: 0px 0px 0px rgba(25, 133, 39, 0.164);

        @media screen and (max-width: 950px) {
            top: 187px;
        }
    }
}

.app__logoTapas {
    position: relative;
    top: 0;
    height: 15vw;
    margin-left: -10px;
    width: 65%;
    height: 350px;
    margin-top: -50px;
    margin-bottom: -100px;
    animation: introHeaderOne 1s ease-in;

    @media screen and (max-width: 600px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 25%;
        margin-top: 20px;
        animation: none;
        height: auto;
    }

    @media screen and (max-width: 950px) and (min-width:600px) {

        width: 75%;
        height: 35vw;
        animation: none;

    }

    @media screen and (max-width: 1366px) and (min-width:950px) {

        width: 50%;
        height: 200px;
    }
}

.app__button1 {
    margin-right: 10px;
    position: relative;

    @media screen and (max-width: 600px) {
        margin-right: -5px;
        width: 70vw !important;
    }

    @media screen and (min-width: 600px) and (max-width:950px) {
        margin-right: 0px;


    }
}

.app__button2 {
    position: relative;
    margin-left: 10px;
    box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.151) !important;
    color: white !important;

    @media screen and (max-width: 600px) {
        margin-left: 5px;
        margin-top: 30px !important;
        width: 70vw !important;

    }
}

.app__button2:hover {
    top: 187px;
    background-color: rgb(16, 15, 88) !important;
    border-style: none;
    box-shadow: 0px 0px 0px rgba(25, 133, 39, 0.164) !important;

    @media screen and (max-width: 1000px) {
        top: 0px;
    }
}

@keyframes introHeaderOne {
    0% {
        top: 10vh;
        opacity: 0;
    }

    80% {
        top: 10vh;
        opacity: 0;
    }

    100% {
        top: 0vh;
        opacity: 1;
    }
}

@keyframes introHeaderTwo {
    0% {
        top: -10vh;
        opacity: 0;
    }

    80% {
        top: -10vh;
        opacity: 0;
    }

    100% {
        top: 0vh;
        opacity: 1;
    }
}