.app__whitebg {
  background-color: white !important;
}


.line {
  width: 5%;
  height: 1px;
  background-color: tan;
  margin: 30px 0 0 0;
  @media screen and (max-width: 650px) {
margin: 0px;
        width: 10%;
        margin-top: -20px;
      }
}

.head-text-skills {
  
  padding-top:30px ;
    font-size: 50px !important;
  font-weight: lighter;
   color: rgba(5, 26, 50, 0.9);
   font-family: "sans-serif",  !important;

  text-align: center;


  @media screen and (max-width: 650px) {
   
padding: 0px;
    font-size: 23px!important;
    margin-left: 0px !important;
   height:250px;
    text-align: center;
    margin-bottom: -150px!important;
  }

  @media screen and  (max-width: 950px) and(min-width:650px) {
    width: 100%;
    font-size: 17px;
    margin-bottom: -10px !important;
    font-family: sans-serif;
    margin-top: 0px;
    
    
    margin-left: 0px !important;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    display: block;
    font-size: 17px;
    margin-bottom: -150px;
    font-weight: 100;
    font-family: sans-serif;
    color: #1f1f1f !important;
    margin-top: -20px;
    margin-left: 50px;
    font-size: 4rem;
    margin-left: 0px !important;
    text-align: center;
  }
}

#galeria {
  overflow: hidden;

  @media screen and (max-width: 650px) {
    overflow: visible !important;
  }

  @media screen and (max-width: 950px) {
    overflow: visible !important;
  }

  @media screen and (max-width: 1000px) {
    overflow: hidden;
  }
}

.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow-y: hidden;

  @media screen and (max-width: 1000px)and (min-width: 650px) {
    overflow: visible;
  }

  @media screen and (max-width: 650px) {
    overflow: visible;
    height: auto;

  }
}

.app__skills-container {
  width: 90%;
  margin-top: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 950px)  and (min-width: 650px) {
    width: 100vw;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }

  @media screen and (max-width: 650px) {

    width: 100%;
    flex-direction: column;
    margin-left: 0px;
    overflow: hidden;
   

  }

 
}

.app__skills-list {
  flex: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
  margin-right: 0rem;
  position: relative;
  transform: translate(0%, 10%);
  user-select: none;
  z-index: 0;
  @media screen and (min-width: 2000px) {
  transform: (0%,50%);
}

  @media screen and (max-width: 650px) {
    margin-right: 0;
    flex-wrap: wrap;
    width: 100%;
   
  }

  @media screen and (max-width: 1000px) and (min-width: 650px) {
    margin-bottom: 100px;
    flex-wrap: wrap;
    width: 100vw;
    height: auto;
    padding: 0px;
  }
}

.app__skills-item {
  text-align: center;
  width: 23vw;
  margin: 1rem;
  position: relative;
  top: 0;
  display: flex;
  border-style: solid;
  height: 300px;
  border-radius: 10px;
  border-color: rgba(41, 41, 41, 0);
  justify-content: flex-start !important;
  overflow: hidden;
  height: 36vh !important;
  filter: grayscale(.5);
  @media screen and (min-width: 2000px) {
    width:25vw;
    height: 30vh!important;
    
}
  transition: all .3s ease-in-out;

  @media screen and (max-width: 650px) {
    width: 100%;
    flex-basis: 100%;
    height: 200px !important;
  }

  @media screen and (max-width: 950px) and (min-width: 650px) {
    width: 40vw!important;
    height: 27vh !important;
  }



  div {
    width: 100%;

    height: 100%;
    border-radius: 20px;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fef4f5;
    transition: all .7s ease;

    position: relative;
    top: 0;
    box-shadow: none;

    h1 {
      
      font-size: 80px;
      font-weight: bolder;
      margin-bottom: -10px;
      @media screen and (max-width: 600px) {
        font-size: 23px!important;
        margin-top: 0px!important;
        font-weight: 100;
        font-family: sans-serif;
        color: #d1b9b5;
        margin-top: -20px;
        font-size: 15px;
        margin-bottom: 10px;
      }

    }

    h2 {


      @media screen and (max-width: 600px) {
        font-size: 17px;
        font-weight: 100;
        font-family: sans-serif;
        color: #d1b9b5;
        margin-top: 0px;
        font-size: 15px;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 950px) {
        font-size: 17px;
        margin-top: -10px;
        font-weight: 100;
        color: #d1b9b5;
        margin-top: -20px;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 80%;
      height: 10px;
      flex-basis: 60%;
    }
  }

  &:hover {

    width: 25vw;
    height: 39vh !important;

    background-color: rgba(0, 0, 0, 0);
    color: #02012e25 !important;
    border-radius: 5px;

    filter: grayscale(0);
    @media screen and (max-width: 950px) and (min-width: 650px) {
      width: 45vw!important;
      height: 36vh !important;
    }
    @media screen and (min-width: 2000px) {
      width:26vw;
      height: 36vh!important;

      /* Adjust the width for screens 2000px and wider */
  }

    p {
      color: black
    }

    div {
      background-color: #ffffff00;
      color: rgb(37, 37, 37);

      box-shadow: 0 20px rgba(61, 61, 61, 0);
    }
  }

  p {
    font-weight: 100;
    margin-top: 1rem;
    width: 140px;
    text-align: justify;
    color: rgb(31, 31, 31);
    font-family: sans-serif;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

.app__skills-exp-works {
  flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;

    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;


}

.img-galery {
  height: 100%;
  width: auto;
  object-fit: cover !important;
  transition: all .2s ease;
 



  @media screen and (max-width: 650px) {
    width: 350px;
    height: auto;
    object-fit: none;
  }

  @media screen and (min-width: 650px) and (max-width: 950px) {
  
    width:50vw;
    height: 30vh;
  }
  @media screen and (min-width: 2000px) {
    width:25vw;
    height: 35vh;
}
}

.app__skills-galery {
  height: 100%;
  width: 100%;
  box-shadow: inset 0 20px 0px rgba(17, 17, 17, 0.514);


  @media screen and (max-width: 600px) {
    width: 400px;
    height: 400px;
  }


  @media screen and (max-width: 950px) and (min-width: 600px) {
    width: 400px;
    height: 400px;
  }
}

.work-filter-item {
  
  display: inline;
  margin: 1rem 0;
  cursor: pointer;
  font-size: 18px !important;
  color:rgba(194, 190, 190, 0.862);

  position: relative; /* Add position relative to position the pseudo-element */
}

.work-filter-item::after {
  content: "";
  display: block;
  width: 100%; /* This will create a border that spans the width of the text */
  height: 2px; /* Adjust the thickness of the border as needed */
  background-color: rgba(233, 229, 229, 0.862);
  position: absolute;
  bottom: 0;
  left: 0; 
  transition: background-color, color 0.3s ;
}
.work-filter-item.item-active {
  color: #000000; /* Change text color for active item */
}

.work-filter-item.item-active::after {
  background-color: #000000; /* Change border color for active item */
  color: #000000; /* Change text color for active item */
}
.work-filter {
  gap: 15px!important;
  
  flex-wrap: wrap;

  display: flex;
  flex-direction: row;
  margin-bottom: -15px;
  @media screen and (max-width: 650px) {
width: 100vw;
padding: 10px;
margin: 0px!important;
flex-wrap: wrap;
justify-content: center;

}
@media screen and (min-width: 650px) and(max-width: 950px) {
  width: 100vw;
  
    margin: 0px!important;
  flex-wrap: wrap;
  justify-content: center;
  
  }
    
  
}
