.footer-container {
  border-top: solid 0.5px rgb(42, 40, 57);
  z-index: 0;
  background-image: url("../../assets/footerBackground.jpg");
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100vw;
  @media screen and (width: 650px) {
    justify-content: center;
    width: 100vw;
    align-items: center;
    background-image: url("../../assets/footerBackground.jpg");
  }
  @media screen and (min-width: 650px) and(max-width:950px) {
    justify-content: center;
    width: 100vw;
    align-items: center;
    background-image: url("../../assets/footerBackground.jpg");
}
    
  
}
.bottom-desc-7{
  color: transparent !important;
}
//////////copy paste////////////////////////////////////////

.footer-left {
  position: absolute;
  z-index: 999;
  width: 50vw;
  justify-content: start;
  align-items: center;
  border-right: transparent;
  border-top: transparent;
  border-bottom: transparent;
  border-left: transparent;

  box-shadow: 0 10px 70px tan;
  opacity: 1;
  border-radius: 30px;
  padding: 40px;
  padding-top: 0px !important;
  margin-top: -20%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  animation: goUp 1s ease forwards;
  background-image: url("../../assets/footerBackground.jpg");
  background-size:contain ;
  background-position: right;


  @media screen  and(min-width:650px)  and (max-width:950px) {
    width: 90vw;
    @keyframes goUpMobile {
    0% {
      opacity: 1;
      left: 100vw;
    }
  
    100% {
      opacity: 1;
      left: 5%;
    }
  }
  
  /* Apply the media query-specific animation */
  animation: goUpMobile 1s ease forwards;
   
  }
    

  @media screen and (max-width: 650px) {
    margin: 0;
    width: 100vw!important;
    background-image: none;
    background-color:   rgba(16, 16, 16, 0.954)!important;
    

@keyframes goUpMobile {
  0% {
    opacity: 1;
    left: 100vw;
  }

  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Apply the media query-specific animation */
animation: goUpMobile 1s ease forwards;
  }


  @keyframes goUp {
    0% {
      opacity: 1;
      left: 100vw;

      
    }

    100% {
      opacity: 1;
      left: 25%
    }
  }

  h2 {
    color: white!important;

    @media screen and (max-width: 600px) {
      font-size: 30px !important;
    }
  }
}

.app__footer-cards {

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  margin: 2em 0rem;

  @media screen and (max-width: 650px) {


    flex-direction: column !important;

  }

  .app__footer-card {
    width: 400px;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    font-family: sans-serif;

    @media screen and (max-width: 950px) and(min-width:650px) {
      width: 54vw !important;
      flex-direction: column !important;
    }

   

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: tan;
    display: flex;
    justify-content: center;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 10px tan;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: tan;
  display: flex;
  justify-content: center;

}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 15px #f2f7fb;
}

.app__footer-form {
  width: 50%;
  flex-direction: column;
  margin: -2rem 2rem 3rem 2rem;

  @media screen and (max-width: 650px) {
    width: 10vw !important;


  }

  @media screen and (max-width: 768px)and (min-width: 650px) {
    width: 50vw !important;
  }

  @media screen and (max-width: 1000px)and (min-width: 768px) {
    width: 80vw !important;
  }

  div {
    width: 22vw;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);
    @media screen and (max-width: 650px) {
      width:90vw !important;;
      display: flex;

    }
    @media screen and (min-width: 650px) and(max-width:950px) {
      width:54vw !important;;
      display: flex;

    }
    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 21vw;
      padding: 0.95rem;
    border: white solid 0.5px;
     
    

      font-family: var(--font-base);
      color: var(--secondary-color);
     


      @media screen and (max-width: 650px) {
        width: 88vw;
        display: flex;
        border-radius: 2%;

      }
      @media screen and (min-width: 650px) and(max-width:950px) {
        width: 53vw;
        display: flex;
        border-radius: 2%;

      }
    }


    textarea {
      margin: auto;
      height: 170px;
    }


    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    font-family: sans-serif !important;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: tan;
    display: flex;
    justify-content: center;
    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);
    color: #05121d;
    width: 400px;
    transition: all .5s ease;
    cursor: pointer;

    &:hover {
      background-color: #e1ba7c;
      color: black;
    }

    @media screen and (max-width: 650px) {
      width: 200px;
    }
  }

  @media screen and (max-width: 650px) {
    width: 0%;
    margin: 1rem 0;
  }

 

  @media screen and (max-width: 992px) and (min-width: 650px)  {
    width: 50vw!important;
    margin: 1rem 0;
  }

  @media screen and (max-width: 1200px) and (min-width:992px) {
    width: 100%;
    margin: 1rem 0;
  }
}


.parent-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;


  @media screen and (max-width: 950px) and (min-width: 650px)  { 
    height: auto;

}


  @media screen and (max-width: 650px) {
    width: 100vw!important;
    

  }

  div {
    display: flex;
    flex-direction: column;


   
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 300px;

}

.head-text-footer {
  margin-bottom: -50px;
  color: rgb(255, 255, 255) !important;
  font-size: 3rem;
  margin-bottom: 0px;
}

.normal-text {
  font-family: sans-serif !important;
}

#contact {
  overflow: hidden !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}




.map-title {
  color: rgb(36, 36, 36) !important;
  font-size: 30px !important;
  font-weight: 100;
  margin-bottom: 60px;
  z-index: 1;

  @media screen and (max-width: 650px) {
    font-size: 30px !important;
    margin-left: -20px;
    text-align: center;
    line-height: 35px;
  }
  @media screen and (min-width: 650px) and (max-width:950px) {
    font-size: 70vw !important;
  }
}

.map-desc {
  margin-top: 30px;
  font-size: 45px;
  font-weight: 500 bolder;

  color: white;
  text-align: center;

  @media screen and (max-width: 650px) {
    width: 100vw !important;
    height: 400px;
font-size: 30px;

    outline: solid rgb(227, 188, 134);
    padding: 15px;
    padding-top: 50px;

  }

  @media screen and (min-width: 650px) and (max-width:950px) {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
font-size: 30px;
height: auto!important;
  }

}


.buttonParent {
  align-items: center;


  @media screen and (max-width: 650px) {
    margin-left: -40px;

  }

}

.map-button {

  border: none;

  margin-top: 0;
  cursor: pointer;

  margin-bottom: 30px;

  text-align: center;
@media screen and(min-width:650px) and (max-width:950px) {
  
  width: 48%;
  padding-top: 30px;
}
}

.bottom-desc-5 {
  color: transparent !important;
}



@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }

}


// Example 1.
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  background: tan;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
  margin-right: -70%;
  margin-top: 15%;

  @media screen and (max-width:650px) {
    margin-right: -100%;
    margin-bottom: 30px;
  }

  .icon-cross {
    @include cross(30px, white, 6px);

  }

  &:hover,
  &:focus {
    transform: rotateZ(90deg);
    background: #cf800a;

  }

}

.double-arrows {
  color: var(--secondary-color);
  font-size: 24px;
  margin-left: 35px;



}

.chat-button-parent {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  gap: 11px;
  justify-content: center !important;
  padding-top: 10px;

@media screen and (max-width:650px) {
  padding-left: 55px;
  
}
@media screen and (min-width:650px) and (max-width:950px) {
padding: 0;  
width: 50vw;
margin:0px
}
}

a {
  text-decoration: none !important;
}


.animated-text {
  font-size: 15px;
  position: relative;
  display: inline-block;
  color: transparent;
  background-image: linear-gradient(90deg, transparent, rgb(216, 165, 98), transparent);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: textAnimation 4s linear infinite, textMove 2s ease-in-out infinite;
}

@keyframes textAnimation {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}


.left,
.right {
  font-size: 22px;
  color: rgb(216, 165, 98);
  animation: colorBlink 3s linear infinite, arrowMove 2s ease-in-out infinite;

}

@keyframes colorBlink {

  0%,
  100% {
    color: transparent;
  }

  50% {
    color: rgb(216, 165, 98);
  }
}

@keyframes arrowMove {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-3px);
    /* Adjust as needed for the desired movement */
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes textMove {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1px);
    /* Adjust as needed for the desired movement */
  }

  100% {
    transform: translateY(0);
  }
}








///footer//////////////////////////////////////////////

.parent-bottom {


  height: auto;

  display: flex;
  justify-content: space-around;
  align-items: center;

  align-content: center;

  // background: linear-gradient(to top, rgb(2, 2, 32) 90%, rgb(88, 88, 138))
  flex-direction: column;



  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 50px;
    padding-bottom: 50px;
    margin-top: 200px;
  }
  @media screen and (min-width: 650px) and (max-width:950px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 50px;
    padding-top: 120px;
  }

  .parent-bottom-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



    .bottom-img-1 {
      height: 170px;
      width: 90%;
      margin-left: -60px;

      @media screen and (max-width: 650px) {
        height: 170px;
        margin-left: 30px;
        margin-top: 50px;
        width: 60%;
      }
    }

    .bottom-img-2 {
      height: 100px;

      @media screen and (max-width: 650px) {
        height: 80px;
        margin-left: 50px;
      }
    }

    p {
      color: rgb(68, 64, 107);
      margin-left: -25px;
      margin-top: -30px;

      @media screen and (max-width: 650px) {

        margin-left: 30px;
      }
    }
  }

  .parent-bottom-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;


    @media screen and (max-width: 650px) {
      align-items: center;
      margin-left: 25px;
    }

    h1 {
      color: rgb(227, 188, 134);
      font-size: 22px;
      @media screen and (max-width: 650px) {
        font-size: 19px;
        }
        @media screen and (min-width: 650px) and (max-width: 950px) {
          font-size: 26px!important;
          }
    }

    p {
      color: rgb(212, 212, 212);
      font-size: 16px;
      font-weight: bold;
      @media screen and (max-width: 650px) {
        font-size: 14px;
        }
    }

  }
}

.child-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  gap: 200px;

  margin-left: 55px;


  @media screen and (max-width: 650px) {
    margin: 0 !important;
    width: 100vw !important;
    gap: 0px !important;
    display: flex !important;
    flex-direction: column !important;
    margin-top: -100px!important;
  }
  @media screen and (min-width: 650px)and (max-width:950px) {
    justify-content:space-between;
    align-items: center;
    margin-left: 0px!important;
    margin-top: -75px!important;
gap: 0px !important;
   
  }
}




.copyright2 {
  padding: 10px;
  border-top: rgb(0, 0, 0) solid 1px;
  color: rgba(212, 212, 212, 0.598);
  font-size: 12px;
  width: 100vw;
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: 15px;


  margin-top: 10px;
  /* Add spacing between the other content and the copyright */

  @media screen and (max-width: 650px) {
    font-size: 10px;
    flex-direction: column;
    gap: 0px;
    align-items: center;

  }

  @media screen and (min-width: 650px) and (max-width:950px){
    font-size: 10px;
    gap: 0px;
  }



}

.icons-footer {
  color: rgb(227, 188, 134);
  font-size: 45px;
@media screen and (max-width: 650px) {
font-size: 35px;
}
}

.icon-row {

  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.social-footer {
  display: flex;
  flex-direction: row;
  gap:25px;
  font-size: 30px;
  height: 55px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 650px) {
    
    padding-bottom: 30px;

    
  }
}

.socialLogo-footer {
  color: tan !important;
  transition: transform 0.2s ease-in-out;
}

.socialLogo-footer:hover {
  /* Move the logo 3 pixels up (adjust as needed) */
  transform: translateY(-3px);
}

.line-footer {
  border: #f2f7fb12 solid 0.5px;
  width: 100vw;
}