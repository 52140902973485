@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Merriweather:wght@300&family=Montserrat:wght@100&family=Playfair+Display&family=Poppins:wght@400;500;600;700&display=swap');
:root {
  --font-base:  'Crimson Text', sans-serif;
  --primary-color: rgb(255, 255, 255);
  --secondary-color: #0f0f3a;
  --shadow-color: rgb(224, 213, 150);
  --black-color: rgb(24, 24, 24);
  --lightGray-color: #e4e4e4;
  --gray-color: #ffffff;
  --brown-color: #ffffff;
  --white-color: #0f134b;
  width: 100vw !important;
  overflow-x: hidden;
  /* @media screen and (max-width: 600px) {
     display: none;
   width: 100vw!important;     
} */

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  line-height: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--shadow-color)
}