.services-parent {
  width: 100vw;
  height: 500px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: top;
  background-color: #1b1b1b;

  @media screen and(max-width:650px) {
    width: 100vw;
    height: auto !important;
    margin: 0px;



  }

  @media screen and(max-width:1150px) and (min-width:650px) {
    width: 100%;
    height: auto !important;

  }


  .services-row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;

    @media screen and(max-width:650px) {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100vw;
      height: auto !important
    }

    @media screen and(max-width:1150px) and (min-width:650px) {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100vw;
    }


  }

  .services-title {
    padding: 30px;
    width: 100%;
    text-align: center;
    font-size: 40px !important;
    color: white !important;
    font-weight: 100;


  }

  .services-desc {
    color: rgb(112, 112, 112) !important;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center !important;
    margin-top: 10px;
    gap: 5px;
    justify-self: flex-start !important;



    span {
      color: white !important;
      margin-left: 10px;
      height: 50px;
      padding: -10px;
      margin-bottom: 5px;
      font-size: 20px;

      @media screen and(max-width:650px) {
        color: rgb(227, 188, 134) !important;
      }

      @media screen and (max-width: 1150px) and (min-width:950px) {
        font-size: 29px !important;

      }

    }

  }
}

.work-container {
  position: relative;

  z-index: 1;
  border-radius: 0px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 0px !important;




  @media screen and (max-width: 650px) {
    position: relative;
    background-color: transparent;
    box-shadow: 0 0 0 black;
    top: 50px;
    left: auto;
    height: 100%;
    width: 100vw;
    transform: translate(0, 0);

  }

  @media screen and (max-width: 1150px) and (min-width:650px) {
    position: relative;

  }

  .house-img {
    width: 30vw;

    @media screen and (max-width:650px) {
      width: 350px;

    }

    @media screen and (max-width: 950px) and (min-width:650px) {
      width: 60vw;

    }

    @media screen and (max-width: 1150px) and (min-width:950px) {
      width: 70vw;

    }

    // animation: rotateImage 4s linear infinite alternate
  }

  // @keyframes rotateImage {
  //   0% {
  //     transform: rotateY(10deg); // Slight initial rotation
  //   }

  //   50% {
  //     transform: rotateY(-5deg); // Rotate to the other side
  //   }

  //   100% {
  //     transform: rotateY(10deg); // Return to the initial side
  //   }
  // }

  .text-container-child {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
    padding-bottom: 50px;

    @media screen and (max-width: 650px) {
      flex-direction: column;

    }

    @media screen and (max-width: 1150px) and (min-width:650px) {
      flex-direction: column;
    }

  }

  .text {
    display: flex;
    flex-direction: column;

    align-items: center !important;
    justify-content: center !important;
    width: 50% !important;

    @media screen and (max-width: 650px) {
      width: 100% !important;

    }

    @media screen and (max-width: 1150px) and (min-width:650px) {
      width: 100% !important;
    }


  }

  .slogan-work {
    color: tan;

    text-align: center;
    padding-bottom: 15px;

    @media screen and (max-width: 650px) {

      font-size: 12px;

    }

  }


  p {
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: rgb(165, 164, 164) !important;
    width: 75%;
    margin-top: 0vw;

    @media screen and (max-width: 650px) {
      width: 80%;
      font-size: 14px;
      text-align: justify;

    }

    @media screen and (max-width: 950px) and (min-width:650px) {
      font-size: 19px;
      padding-bottom: 50px;
      justify-content: center;
    }

    @media screen and (max-width: 1150px) and (min-width:950px) {
      font-size: 20px;
      padding-bottom: 50px;
      justify-content: center;
    }
  }
}

.exemplos {
  font-size: 14px !important;
  text-align: justify;
  width: 75% !important;
  height: auto !important;
  padding-left: 10px;

  @media screen and (max-width: 1150px) and (min-width:950px) {
    font-size: 18px !important;

  }
}

h1 {

  font-size: 40px !important;
  font-weight: lighter;
  color: rgba(5, 26, 50, 0.9);
  text-align: center;
  width: 90%;
  padding-bottom: 20px;

  @media screen and (max-width: 650px) {
    width: 100vw;
    font-size: 23px !important;
  }

}



button {
  height: 50px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: -20px;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 29, 29);
  background-color: transparent;
  transition: all .2s ease;

  &:hover {
    background-color: #18181821;
  }

  a {
    color: #1b1b1b;
    text-decoration: none;
  }
}

.app__works {
  flex: 1;
  width: 70%;
  flex-direction: column;



  padding-bottom: 50px;
  border-radius: 25px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  @media screen and (max-width: 650px) {
    width: 100%;
    margin-top: -50px;
    padding-top: 0px;

  }

  @media screen and (max-width: 1150px) and (min-width:650px) {
    width: 100%;

  }
}







.line-menu {
  height: 1px;
  border-top: 3px dotted rgb(204, 204, 204);
  ;
  background-color: rgba(168, 168, 168, 0);
  width: 100%;
  margin-bottom: -13.5px;
}


.icons-works {
  font-size: 40px;
  color: tan;

  @media screen and (max-width: 1150px) and (min-width:950px) {
    font-size: 60px !important;
    padding-bottom: 5px;

  }
}

.line1 {
  width: 10%;
  height: 1px;
  background-color: tan;
  margin: 0px 0 50px 0;
}

.orcamento {
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  gap: 5px;
  margin-top: 30px;
  margin-bottom: -20px;
  height: 50px;
  position: relative;
  text-align: center;
  background: none;

  color: rgb(199, 151, 87);
cursor: pointer;
  font-weight: bold;
  font-size: 15px;

  @media screen and(min-width:650px) and (max-width:1150px) {
    font-size: 18px;
    margin: 0px !important;
    margin-bottom: 20px !important;

  }

  @media screen and(max-width:650px) {
    font-size: 15px;
    margin: 0px !important;
  }


  transition: background-color 0.3s ease,
  transform 0.3s ease;
  /* Transition for background-color property */
}

// .arrowspan {
//   margin-top: 5px;
//   font-size: 19px;
//   text-align: center !important;
//   color: rgb(202, 146, 72);
//   animation: moveRightAndBack 2s linear infinite;
//   /* Infinite loop animation */
//   transition: color 0.3s;
//   /* Transition for color change */

// }

.button-text:hover {
  transform: translate3d(0, -5px, 0);
  /* Move the button 5px down when hovering */
  /* Change to the desired hover color */
}

.orcamento:hover {
  transform: translate3d(0, -5px, 0);
  /* Move the button 5px down when hovering */
}