
.app__testimonials-title {
  padding-top:30px ;
  font-size: 50px !important;
font-weight: lighter;
 color: rgba(5, 26, 50, 0.9);
 
text-align: center;
@media screen and (max-width: 650px) {
 color:rgb(255, 255, 255)
}
}


/* Testimonial Navigation Buttons */
.app__testimonial-btns {
  display: flex;
  align-items: center; /* Center content vertically */
  margin-top: 1rem;

  div {
    width: 120px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: solid 1px rgb(212, 212, 212);
    position: relative;
    z-index: 1;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color:  rgb(150, 150, 150)
    }

    &:hover {
      background-color: var(--secondary-color);

      svg {
        color: white;
      }
    }
  }
}

// /* Brands Container */
// .app__testimonial-brands {
//   width: 80%;
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: 2rem;
//   justify-content: center; /* Center brands horizontally */

//   div {
//     width: 150px;
//     margin: 1.5rem;

//     img {
//       width: 100%;
//       height: auto;
//       object-fit: cover;
//       filter: grayscale(1);
//       transition: filter 0.3s ease-in-out;
//     }

//     &:hover {
//       img {
//         filter: grayscale(0);
//       }
//     }
//   }
// }
#app__testimonial{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: 
  url(../../assets/background-contact.jpg );
  background-size: cover;
  padding: 3vw 0;
  margin-bottom: 0;
  background-repeat: no-repeat;

  
  
}
.testimonial-parent {
  width: 78%;
  height: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  padding: 30px 0px;
  background-color: rgba(255, 255, 255, 0.771);
  border: solid 1px rgb(219, 219, 219);
  border-radius: 15px;

  @media screen and (max-width: 650px) {
      flex-direction: column;
      height: auto;
      border:none;
      width: 100vw;
      padding: 0px;
  }

@media screen and (max-width: 950px) and (min-width:650px) {
  flex-direction: column;
  height: auto;
  padding-top: 20px;
}

@media screen and (max-width: 1550px) and (min-width:950px) {

  height: auto;
  padding-top: 0;
  width: 80vw;
}


}
.img-parent-testimonial{
  
  position: relative;
  z-index: 1;
  @media screen and (max-width: 650px) {
    width: 100vw;
                        
  }

@media screen and (max-width: 950px) and (min-width:650px) {
}
  
}
.img-testimonial {  
  height: 450px;
  max-width: 500px;
  overflow: hidden;
  border-radius: 15px;
 @media screen and (max-width:650px){
   left: 0%;
   width: 100%;
   height: auto;
   opacity: 0.9;
   object-fit: contain;

  }
  
  @media screen and (max-width: 1550px) and (min-width:650px) {
    object-fit: contain;
    height: auto;
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;
  }
}
.testimonial-desc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  width: 60%;
  height: 100%;
  padding: 40px;
  color: rgb(71, 64, 64) !important;
  // box-shadow: 0 -20px 50px 50px white;
  text-align: justify;
  border-style: none;
  border-width: 1px;

  @media screen and (max-width: 650px) {
      width: 100vw;
      height: 100%;
      border-style: none;
  }

@media screen and (max-width: 950px) and (min-width:650px) {
  width: 100%;
  height: 100%;
  border-style: none;
}
  h1 {
      margin-bottom: 30px;
      color: rgb(49, 47, 47) !important;
  font-size: 30px !important;
    font-weight: 100;
    line-height: 30px;
    text-align: center;
    

      @media screen and (max-width: 650px) {
        color: rgba(25, 41, 99, 0.856) !important;

        font-size: 20px!important;
        text-align: center !important;
        padding-bottom: 60px!important;
        font-weight: bolder;
margin: 0!important;  
width: 100% !important;}

@media screen and (max-width: 950px) and (min-width:650px)   {
  font-size: 23px;
  text-align: center;
  margin-bottom: 20px;
}
  }
  p {
      color: rgb(47, 44, 44) !important;
      
      @media screen and (max-width: 650px) {
    }
    @media screen and (max-width: 950px) and (min-width:650px) {
      margin-top: 40px;
  }
  }
}


.line-testimonials {
  width: 5%;
  height: 1px;
  background-color: rgba(8, 11, 38, 0.666);
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
  @media screen and (max-width: 650px) {
   color: white !important;
  }
}
.button-testimonial{
  opacity: 0.5!important;
}