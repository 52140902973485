.app__navbar {

    font-family: sans-serif;
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;
    background: rgba(233, 232, 232, 0);
    border: nonw;
    position: fixed;
    z-index: 2;

    animation: introHeaderTwo 1s ease-in;
    @media screen and (max-width: 650px){
      width: 100vw;
      
    }
    @media screen and (max-width: 950px) and(min-width: 650px){
        width: 100vw;
        
      }
      @media screen and (min-width: 950px) and(max-width: 1366px){
        width: 100vw;
        
      }
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    filter: brightness(1);

    img {
        width: 180px;
        height: 90px;
        margin: -20px -120px -20px 0;
        @media screen and (max-width: 650px){
            width: 145px;
            height: 70px;
            margin: -20px -159px -20px 0;
            margin-left: -35px;
        }

        @media screen and (min-width: 2000px) {
            width: 240px;
            height: auto;
        }
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;

        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;

            margin-bottom: 5px;

            transition: all .35s ease;
        }

        a {
            color: rgb(255, 255, 255);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;

            font-weight: 500;
            transition: all .2s ease;

            &:hover {
                color: var(--secondary-color)
            }
        }

        &:hover {
            div {
                background: var(--secondary-color);
            }
        }
    }

    @media  (max-width: 950px) {
        display: none;        
    }
}

.app__navbar-menu {
    z-index: 999;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -40px;
    background: var(--secondary-color);

    svg {
        width: 70%;
        height: 70%;
        color: white;
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        
        background-color: rgb(2, 2, 18);
        background-size: cover;
        background-repeat: repeat;
        

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
        width: 35px;
        height: 35px;
        color: rgb(227, 188, 134);
        margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: rgb(227, 188, 134);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    @media screen and(max-width: 950px)and (min-width: 650px){
                        font-size: 1.5rem;
                        color: rgba(239, 215, 182, 0.905);

                    }
                    transition: all .2s ease;

                    &:hover {
                        color: var(--secondary-color)
                    }
                }
            }
        }

        @media screen and (min-width: 950px) {
            display: none;
        }
    }

    @media screen and (min-width: 950px) {
        display: none;
    }
}
.civiprimeMixColor{
 
  @media screen and(max-width: 650px){
    
        width: 50%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        margin-top: -50px;
        
    
}
@media screen and(max-width: 950px)and (min-width: 650px){
    width: 50%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}
}
.social-navbar{
    display: none;
    @media screen and(max-width: 650px){
    
        width: 50%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        margin-top: -50px;
        
    
}
@media screen and(max-width: 950px)and (min-width: 650px){
    height: 50px!important;
    width: 70%!important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}
}
    

.lili{
    color: white;
}